.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.App-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.App-form {
  margin-bottom: 20px;
}

.App-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-form label {
  margin-bottom: 10px;
  font-size: 18px;
  color: #282c34;
}

.App-form input {
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 200px;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.1);
}

.App-form input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0,123,255,0.5);
}

.App-form button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.1);
}

.App-form button:hover {
  background-color: #0056b3;
}

.App-form button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0,123,255,0.5);
}

.App-map-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
}

.App-map {
  flex: 2;
  max-width: 800px;
  margin-right: 20px;
}

.App-timezones {
  flex: 1;
  margin-top: 20px;
}

.App-timezones h2 {
  margin-bottom: 10px;
}

.App-timezones ul {
  list-style-type: none;
  padding: 0;
}

.App-timezones li {
  margin: 5px 0;
}

.App-fact {
  margin-top: 20px;
}

.App-fact h3 {
  margin-bottom: 10px;
}

.App-footer {
  background-color: #282c34;
  padding: 10px;
  color: white;
  text-align: center;
}

.App-footer a {
  color: #61dafb;
  text-decoration: none;
}

.App-footer a:hover {
  text-decoration: underline;
}
